import React from "react";
import {
  TextContainer,
  IngressContainer,
  InfoContainer,
  IngressHeader,
  Bread,
  BaseContainer,
} from "./../style/lib";
import { FollowUs } from "./../gsap/followIcons";
import { FadeInOnScroll } from "../gsap/scrollTrigg";
import * as Style from "./../style/contact-style";


export const Contact: React.FC = () => {


  return (
    <BaseContainer
      $backgroundColor="var(--bg-pink)"
      $padding={['0.4rem 0 4rem', '1rem 0 5rem', '2rem 0 7rem']}
      id="contactus">
      <TextContainer>
        <InfoContainer>
          <FadeInOnScroll>

            <IngressContainer>
              <IngressHeader>
                Är du lika nyfiken på
                oss som vi är på dig?
              </IngressHeader>
            </IngressContainer>

            <Bread $margin={{ mobile: '8px 0' }}>
              Vi älskar att träffa nya människor och skapa nya kontakter.<br />
              Säg hej om ni vill veta mer om oss eller har något
              roligt att berätta!
            </Bread>

            <Style.ContactContainer>
              <Style.ContactHeader>
                Maila oss på
              </Style.ContactHeader>
              <Style.LinkForWork
                href="mailto: info@andtech.se"
              >
                info@andtech.se
              </Style.LinkForWork>
            </Style.ContactContainer>

            <Style.ContactContainer>
              <Style.ContactHeader>
                Besök oss
              </Style.ContactHeader>
              <Style.ContactInfo>
                Stockholm <br />
                Bondegatan 30<br />
                116 33 Stockholm<br />
              </Style.ContactInfo>
            </Style.ContactContainer>



            <FollowUs />

          </FadeInOnScroll>
        </InfoContainer>
      </TextContainer>
    </BaseContainer>
  )
}