import styled from 'styled-components';


export const Intro = styled.div`
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 background-color: var(--maindark);
 height: 97vh;
`;