import styled from 'styled-components';

export const FromInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0;
  min-width: 100%;


  @media (min-width: 600px) {
    margin: 10px 10px 8px 0;
  }

  @media (min-width: 768px) {
    min-width: 350px;
  }

  @media (min-width: 1024px) {
    min-width: 350px;
  }
`;

export const RowContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0;
`;

export const Label = styled.label`
  font-family: 'Inter', sans-serif;
  font-weight: 200;
  margin-bottom: 8px;
  font-size: 16px;
  text-transform: uppercase;

  @media (min-width: 768px) {
    font-size: 18px;
  }

`;

export const InputContacts = styled.input`
  padding: 8px;
  border: 1px solid var(--darktext);
  border-radius: 0;
  font-size: 16px;
  background-color: var(--fade-green); 
`;

export const Textarea = styled.textarea`
  padding: 8px;
  border: 1px solid #050024ac;
  border-radius: 0;
  font-size: 16px;
  resize: vertical;
  min-height: 100px;
  background-color: var(--fade-green); 

  @media (min-width: 600px) {

  }

  @media (min-width: 768px) {
    max-width: 710px;
  }
`;

export const ErrorMessage = styled.p`
  color: var(--darktext);
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  margin: 8px 0;
`;


//Chechboxing
export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0;
`;

export const CheckboxInput = styled.input`
  appearance: none; /* Remove default styling */
  -webkit-appearance: none; /* Safari */
  -moz-appearance: none; /* Firefox */
  width: 20px;
  height: 20px;
  border: 2px solid #050024ac; 
  border-radius: 0px;
  background-color: var(--fade-green); 
  position: relative;
  outline: none;
  margin-right: 8px;

  &:checked::before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px; 
    width: calc(100% - 4px); 
    height: calc(100% - 4px);
    background-color: var(--darktext); 
    border-radius: 0; 
  }
`;

export const CheckboxLabel = styled.label`
    font-family: 'Inter', sans-serif;
    font-weight: 200;
    font-weight: 500;
    font-size: 14px;
`;