import React, { useRef } from 'react';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import styled from 'styled-components';

gsap.registerPlugin(ScrollTrigger);

interface LineGradientSectionProps {
  containerBackgroundColor: string;
  lineStartColor: string;
  lineRightColor: string;
  lineLeftColor: string;
}

const LineGradientContainer = styled.div<{ $backgroundColor: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ $backgroundColor }) => $backgroundColor};
  overflow: hidden;
`;

const LineStart = styled.div<{ color: string }>`
  height: 16px;
  width: 100%;
  margin-top: 3px;
  background: ${({ color }) => color};
`;

const LineRight = styled.div<{ color: string }>`
  height: 14px;
  width: 100%;
  margin-top: 4px;
  background: ${({ color }) => color};
`;

const LineLeft = styled.div<{ color: string }>`
  height: 12px;
  width: 100%;
  margin-top: 5px;
  background: ${({ color }) => color};
`;

export const LineGradientSection: React.FC<LineGradientSectionProps> = ({
  containerBackgroundColor,
  lineStartColor,
  lineRightColor,
  lineLeftColor,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useGSAP(() => {
    const ctx = gsap.context(() => {
      const lines = gsap.utils.toArray<HTMLElement>('.line');

      lines.forEach((line, index) => {
        gsap.from(line, {
          x: index % 2 === 0 ? '-100%' : '100%', // Animates from left for even, right for odd
          duration: 2,
          ease: 'power3.out',
          scrollTrigger: {
            trigger: line,
            start: 'top 80%',
            end: 'top 60%',
            scrub: 1,
            toggleActions: 'play none none reverse',
          },
        });
      });
    }, containerRef);

    return () => ctx.revert();
  });

  return (
    <LineGradientContainer ref={containerRef} $backgroundColor={containerBackgroundColor}>
      <LineStart className="line" color={lineStartColor} />
      <LineRight className="line" color={lineRightColor} />
      <LineLeft className="line" color={lineLeftColor} />
    </LineGradientContainer>
  );
};
