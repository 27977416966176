import React from 'react';
import HamburgerMenu from "./utils/hamburger";
import { Foot } from './pages/footer';

interface MainLayoutProps {
  children: React.ReactNode;
}

export const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  return (
    <>
      <HamburgerMenu />
      <main>{children}</main>
      <Foot />
    </>
  );
};