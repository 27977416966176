import React, { useRef } from 'react';
import { useGSAP } from '@gsap/react';
import { gsap, ScrollTrigger } from 'gsap/all'; // Ensure you import all necessary parts of gsap

// Register ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);

interface FadeInOnScrollProps {
  children: React.ReactNode;
  start?: string;
  duration?: number;
  ease?: string;
  y?: number;
}

export const FadeInOnScroll: React.FC<FadeInOnScrollProps> = ({
  children,
  start = 'top 90%',
  duration = 1.2,
  ease = 'power3.out',
  y = 10,
}) => {
  const elementRef = useRef<HTMLDivElement>(null);

  useGSAP(() => {
    const el = elementRef.current;

    if (el) {
      gsap.fromTo(
        el,
        { opacity: 0, y },  // Initial state
        {
          opacity: 1,
          y: 0,
          duration,
          ease,
          scrollTrigger: {
            trigger: el,
            start,
            toggleActions: 'play none none none',
          },
        }
      );
    }

  }, []);

  return <div ref={elementRef}>{children}</div>;
};
