import React from "react";

import {
  BaseContainer,
  IngressContainer,
  IngressHeader,
  Bread
} from "./../style/lib";
import {
  WorkContainer,
  FormContactHeader,
  FormContactContainer,
  FormContactInfo,
  FormLinkForWork,
} from "../style/workWithUs-style";
import { LottieAnimation } from "../utils/lottieFile";


export const WorkWithUs: React.FC = () => {
  return (
    <BaseContainer
      $backgroundColor="var(--bg-green)"
      $padding={['1rem 0 4rem', '0rem 0 5rem', '1rem 0 7rem']}
    >
      <WorkContainer>
        <IngressContainer>
          <IngressHeader>
            Jobba med oss!
          </IngressHeader>
        </IngressContainer>


        <LottieAnimation />


        <Bread>
          Behöver du vår hjälp eller letar du efter en ny utmaning?<br />
          Har du en spännande idé och behöver vår hjälp eller vill du vara med och utveckla
          framtidens lösningar, säg hej!
        </Bread>
        <Bread $margin={{ mobile: '8px 0' }}>
          Vi på Andtech är alltid nyfikna på personer som älskar teknik och vill vara med
          och klura ut hur vi kan bli smartare och bättre. <br />
          Vi tycker att det viktigaste är att du är människa, med allt vad det innebär, du får gärna
          vara nyfiken och vi tror att din vilja att lära dig nya saker är viktigare än vad du redan kan.
          Vi värdesätter social hållbarhet genom att bry oss om varandra och våra kunder.
          <br /><br />
          Vill du jobba hos oss, skicka ett hej tillsammans med ditt CV till oss så hör vi av oss!

          Genom att maila ditt CV till oss godkänner du att vi lagrar dina personuppgifter
          enligt vår Integritetspolicy.
        </Bread>

        <FormContactContainer>
          <FormContactHeader>
            Maila oss på
          </FormContactHeader>
          <FormLinkForWork
            href="mailto: info@andtech.se"
          >
            info@andtech.se
          </FormLinkForWork>
        </FormContactContainer>

        <FormContactContainer>
          <FormContactHeader>
            Besök oss
          </FormContactHeader>
          <FormContactInfo>
            Stockholm <br />
            Bondegatan 30<br />
            116 33 Stockholm<br />
          </FormContactInfo>
        </FormContactContainer>
      </WorkContainer>



    </BaseContainer>
  )
}