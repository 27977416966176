import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import bubble from '../../assets/bubble.png';
import heart from '../../assets/heart.png';

export const BubbleUp: React.FC = () => {
  const [isHovered, setIsHovered] = useState(false);

  const navigate = useNavigate();

  const handleBubbleClick = () => {
    navigate('/workwithus');
  };

  return (
    <BubbleContainer
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleBubbleClick}
    >
      <Bubble src={bubble} alt="Bubble" />
      <BubbleText>Vill du jobba med oss?</BubbleText>
      {isHovered && <Icon src={heart} alt="flying heart" />}
    </BubbleContainer>
  );
};

// Bubblestyling
const liftUp = keyframes`
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  100% {
    transform: translate(-3px, -3px) rotate(3deg);
  }
`;

const landSoftly = keyframes`
  0% {
    transform: translate(-3px, -3px) rotate(3deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
`;

const flyUpSShape = keyframes`
 0% {
    transform: translate(0, 0) rotate(40deg);
    opacity: 1;
  }
  12.5% {
    transform: translate(-5px, -10px) rotate(35deg);
  }
  25% {
    transform: translate(-10px, -20px) rotate(30deg);
  }
  37.5% {
    transform: translate(0px, -25px) rotate(25deg);
  }
  50% {
    transform: translate(10px, -30px) rotate(20deg);
  }
  62.5% {
    transform: translate(0px, -40px) rotate(15deg);
  }
  75% {
    transform: translate(-10px, -50px) rotate(10deg);
  }
  87.5% {
    transform: translate(0px, -60px) rotate(5deg);
  }
  100% {
    transform: translate(10px, -70px) rotate(0deg);
    opacity: 0;
  }
`;

const Bubble = styled.img`
  width: 160px;
  margin-top: 30px; 
  transition: transform 0.8s;
`;

const Icon = styled.img`
  position: absolute;
  top: 0;
  left: 80%;
  transform: translateX(-50%);
  opacity: 0;
  transition: transform 0.5s, opacity 0.3s;
  width: 40px;
  `;

const BubbleText = styled.p`
position: absolute;
z-index: 1;
font-family: 'Inter', sans-serif;
color: var(--darktext);
font-size: 16px; 
line-height: 24px;
font-weight: 600;
text-align: center;
pointer-events: auto;
text-decoration: none;
width: 90px;
margin-top: 30px;
`;

const BubbleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  position: relative;
  margin: 10px 30px;

  &:hover ${Bubble}, &:hover ${BubbleText} {
    animation: ${liftUp} 0.3s forwards, ${landSoftly} 0.3s 0.3s forwards;
  }

  &:hover ${Icon} {
    animation: ${flyUpSShape} 0.8s forwards;
  }

    /* Tablet */
    @media (min-width: 768px) {
    margin-top: -30px; 
  }

  /* Desktop */
  @media (min-width: 1024px) {
    margin: 0;
  }
`;

