import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PinkButton } from '../style/lib';
import { BaseContainer, BasicHeader, InfoContainer, IngressHeader } from "../style/lib";

export const NotFound: React.FC = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  // Dynamically add a noindex meta tag when this component is mounted
  useEffect(() => {
    const metaTag = document.createElement('meta');
    metaTag.name = 'robots';
    metaTag.content = 'noindex';
    document.head.appendChild(metaTag);

    // Clean up: remove the noindex meta tag when the component is unmounted
    return () => {
      document.head.removeChild(metaTag);
    };
  }, []);

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <BaseContainer $backgroundColor='var(--mainlight)'>
        <InfoContainer>
          <IngressHeader>404 - Page Not Found</IngressHeader>
          <BasicHeader>
            Oops! The page you are looking for does not exist.
          </BasicHeader>
          <PinkButton onClick={handleGoBack} $backgroundColor='var(--mainlight)' style={{ marginTop: '50px' }}>
            Go Back
          </PinkButton>
        </InfoContainer>
      </BaseContainer>
    </div>
  );
};
