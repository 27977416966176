import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/at-logo.svg'

interface BasicContainerProps {
  $backgroundColor?: string;
  $alignItems?: string;
  $padding?: [string, string, string]; // Array of paddings for mobile, tablet, desktop
  $height?: string;
}

export const BaseContainer = styled.div<BasicContainerProps>`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.$backgroundColor};
  padding: ${(props) => props.$padding ? props.$padding[0] : '6rem 0 7rem 0'}; // Mobile padding
  align-items: ${(props) => props.$alignItems || 'center'};
  height: ${(props) => props.$height || 'auto'};

  @media (min-width: 768px) {
    padding: ${(props) => props.$padding ? props.$padding[1] : '8rem 0 9rem 0'}; // Tablet padding
  }

  @media (min-width: 1024px) {
    padding: ${(props) => props.$padding ? props.$padding[2] : '10rem 0 11rem 0'}; // Desktop padding
  }
`;

const SmallLogo = styled.img`
  align-self: flex-end;
  width: 60px;
  margin: 0;

  /* Tablet */
  @media (min-width: 768px) {
    width: 70px;
  }

  /* Desktop */
  @media (min-width: 1024px) {
    width: 90px;
  }
`;


// Mainpage containers
export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

`;

export const IngressContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 80px;
  width: 98%;
`;

interface InfoContainerProps {
  $alignItems?: string;
}
export const InfoContainer = styled.div<InfoContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${(props) => props.$alignItems || 'flex-start;'};
  max-width: 800px;
  margin: 0 20px;

 /* Tablet  */
 @media (min-width: 768px) {
  margin: 0 50px;
  }

  /* Desktop */
  @media (min-width: 1024px) {
    margin: 0 50px;
  }
`;

// AndTech logo for back navigation
export const LogoMini = () => {
  const navigate = useNavigate();

  const scrollToTop = () => {
    navigate('/');
    window.scrollTo(0, 0);
  }

  return (
    <Link to='/' onClick={scrollToTop}>
      <SmallLogo
        src={logo}
        alt="AndTech logo" />
    </Link>)
};


// Pink button
interface PinkButtonProps {
  $backgroundColor?: string;
}

export const PinkButton = styled.button<PinkButtonProps>`
  border-radius: 50px;
  border: 2px solid var(--maindark);
  background-color: ${(props) => props.$backgroundColor};
  color:  var(--maindark);
  font-family: 'safiromedium';
  font-size: 16px;
  width: 120px;
  height: 45px;
  margin-top: 40px;
  
  &:hover {
    background-color:  var(--maindark);
    color: var(--mainlight);
  }
`;


export const IngressHeader = styled.h3`
 color: var(--darktext);
 font-family: 'safirobold';
 font-size: 38px;
 text-align: left;
 text-transform: uppercase;
 line-height: 38px;
 margin-bottom: 18px;


 /* Tablet  */
 @media (min-width: 768px) {
  font-size: 45px;
  margin-bottom: 0;
  line-height: 50px;
  width: 98%;
  }

  /* Desktop */
  @media (min-width: 1024px) {
    font-size: 55px;
    line-height: 70px;
  }
`;

export const BasicHeader = styled.h6`
 color: var(--darktext);
 font-family: 'safiromedium';
 font-weight: normal;
 font-size: 18px;
 text-align: left;
 margin: 30px 0 -18px 0;
 text-transform: uppercase;
`;

interface BreadProps {
  $margin?: {
    mobile?: string;
    tablet?: string;
    desktop?: string;
  };
}

export const Bread = styled.p<BreadProps>`
 color: var(--darktext);
 font-family: 'Inter', sans-serif;
 font-weight: 200;
 font-size: 16px; 
 text-align: left;
 margin: ${(props) => props.$margin?.mobile || '16px 0'};
 line-height: 20px;
 max-width: 97%;

  /* Tablet  */
  @media (min-width: 768px) {
  font-size: 20px;
  line-height: 26px;
  margin: ${(props) => props.$margin?.tablet || '20px 0'};
  align-items: center;
  max-width: 90%;
  }

  /* Desktop */
  @media (min-width: 1024px) {
    font-size: 24px;
    line-height: 30px;
    margin: ${(props) => props.$margin?.desktop || '20px 0'};
    max-width: 822px;
  }
`;

// Sliding Links with underline
interface LinkLineProps {
  $textColor?: string;
  $lineColor?: string;
  $fontSize?: string;
  $fontSizeTablet?: string;
  $fontSizeDesktop?: string;
  to?: string;
}


export const LinkLine = styled(Link) <LinkLineProps>`
  position: relative;
  font-family: 'safiromedium';
  font-size: ${(props) => props.$fontSize || '18px'};
  color: ${(props) => props.$textColor || 'var(--darktext)'};
  text-decoration: none;
  display: inline-block;
  margin: 5px 0;

  &:hover {
    color: ${(props) => props.$textColor || 'var(--darktext)'};
  }

  &::after {
    content: "";
    position: absolute;
    display: inline-block;
    width: 0;
    height: 5px;
    bottom: -2px;
    left: 0;
    background-color: ${(props) => props.$lineColor || 'var(--bg-pink)'};
    transform-origin: left;
    transition: width 0.5s ease;
  }

  &:hover::after {
    width: 100%;
  }

  /* Tablet  */
  @media (min-width: 768px) {
    font-size: ${(props) => props.$fontSizeTablet || '22px'};
  }

  /* Desktop */
  @media (min-width: 1024px) {
    font-size: ${(props) => props.$fontSizeDesktop || '28px'};
  }
`;

// Static links with underline
interface LinkProps {
  $textColor?: string;
  $lineColor?: string;
  $hoverLineColor?: string;
}

export const LinkStatic = styled.a<LinkProps>`
  color: ${(props) => props.$textColor || 'var(--text)'};
  margin: 8px 0;
  font-family: 'Inter', sans-serif;
  font-weight: 200;
  font-size: 16px;
  text-decoration: underline;
  text-underline-offset: 5px;
  text-decoration-thickness: 0.4px;
  text-decoration-color: ${(props) => props.$lineColor || 'var(--text)'};

  &:hover {
    text-decoration-thickness: 4px;
    text-decoration-color: ${(props) => props.$hoverLineColor || 'var(--bg-pink)'};
  }
`;
