import React from "react";
import { TypeHeader, WhatWeDoContainer, DevLogos } from "./../style/whatWeDo-style";
import {
  BaseContainer,
  TextContainer,
  IngressContainer,
  InfoContainer,
  Bread,
} from "./../style/lib";
import system from "../../assets/systemdev.svg";
import devops from "../../assets/devops.svg";
import webdev from "../../assets/webdev.svg";
import { ContactForm } from "../utils/contactForm";

export const WhatWeDo: React.FC = () => {
  return (
    <BaseContainer
      $backgroundColor='var(--bg-green)'
      $padding={['1rem 0 4rem', '2rem 0 5rem', '3rem 0 7rem']}>
      <TextContainer>
        <InfoContainer>
          <IngressContainer>
            <TypeHeader>
              Vi hjälper dig med smarta lösningar som tar er i rätt
              riktning för att snabbt få ny funktionalitet till ett högt värde.
            </TypeHeader>
          </IngressContainer>

          <WhatWeDoContainer>
            <DevLogos src={system} alt="Systemutveckling" />
            <TypeHeader>
              Lösningsarkitektur & systemutveckling
            </TypeHeader>
          </WhatWeDoContainer>
          <Bread>
            Med en strömlinjeformad arkitektur som är framtidssäkrad kommer besparingarna märkas.
            Att ge er det som ni behöver och önskar utan onödiga omvägar är vår drivkraft.
            När vi skapar arkitektur för en lösning har vi därför alltid i åtanke att vi ska
            göra oss umbärliga snarare än oumbärliga. Lösningen är till för er.
            Med vår kompetens och erfarenhet inom molnlösningar kan vi skapa eller flytta era
            applikationer till molntjänster på ett bra sätt. Vi vet hur säkra och
            kostnadseffektiva lösningar ska sättas upp. Vi har hjälpt kunder att halvera sina
            kostnader tack vare smarta strukturer i molnlösningen.
          </Bread>


          <WhatWeDoContainer>
            <DevLogos src={devops} alt="DevOps" />
            <TypeHeader>
              DevOps
            </TypeHeader>
          </WhatWeDoContainer>

          <Bread>
            En bra IT-lösning är lika bra som sin DevOps-lösning.
            Utan struktur och guidelines går mycket tid till enkla saker som bygg,
            test och release. Genom bra Devopslösningar skapas en brygga mellan kod och
            applikation och på så vis säkerställs kvalitet.
            Vi är DevOps-experter och vi kan hjälpa till med att optimera hela livscykeln.
            Automatisering av bygg, test och release leder till stora kostnadsbesparingar.
            Med en bra grund i devops följer också ett arbetssätt som passar för det agila
            teamet. Vi har möjligheten att tillsammans med er ta fram en optimal arbetsgång
            och agera agila coacher för att effektivisera utvecklingsarbetet så att det
            passar er devops-strategi.
          </Bread>

          <WhatWeDoContainer>
            <DevLogos src={webdev} alt="Webutveckling" />
            <TypeHeader>
              Webutveckling
            </TypeHeader>
          </WhatWeDoContainer>

          <Bread>
            Kommunikation, Funktionalitet, Information och Känsla.
            Det är svårt att definiera vad webben används till men dessa 4 känner vi
            är viktiga. Våra konsulter är bra på att förstå vad ni behöver och skapa
            webbsidor som ger rätt värde. Oavsett vad som står i fokus skapas värde genom
            att leverera det enkelt och lättförståeligt.

            Vi har duktiga front-end utvecklare (React & Angular) som kan skapa grunden
            som krävs för er webb. Vi har också duktiga designers som skapar harmoni på
            grunden. Våra konsulter har erfarenheter från mer traditionella webbar till
            datorspel där just user-experience är väldigt viktigt.
          </Bread>


        </InfoContainer>
      </TextContainer>
      <ContactForm />
    </BaseContainer>
  )
}