import {
  Intro,
} from "../style/intro-style";
import { IntroAnimation } from "../gsap/introAnimation";
import { LineGradientSection } from "../gsap/transition";



export const IntroPage: React.FC = () => {
  return (
    <>
      <Intro>
        <IntroAnimation />
      </Intro>
      <LineGradientSection
        containerBackgroundColor="var(--bg-green)"
        lineStartColor="var(--maindark)"
        lineRightColor="var(--maindark)"
        lineLeftColor="var(--maindark)"
      />
    </>
  )
};