import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  
  /* Example of a CSS reset */
  *, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  /* Global styles */
  body {
    background-color: var(--mainlight);
    color: var(--darktext);
    margin: 0;
    font-family: 'Inter', 'safirobold',  sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    scroll-behavior: smooth;
  }

  :root {
    --bg-green: #CAE7DD;
    --bg-pink: #FFA0FF;
    --mainlight: #FFFEF7;
    --maindark: #29032D;
    --blue: #660033;
    --text: #FFF9DE;
    --darktext: #330a1f;
    --fade-green: #cdeae0;
  }

  p {
    margin: 0;
  }

  img, picture, video, canvas, svg {
    display: block;
  }

  input, button, textarea, select {
    font: inherit;
  }

  #root, #__next {
    isolation: isolate;
    height: 100%;
  }

  /* Utility classes */
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }

  /* Global for modal */
  .lock-scroll {
    overflow: hidden;
  }
`;
