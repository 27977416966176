import styled from 'styled-components';

export const AllContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  // big mobole
  @media (min-width: 426px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    margin: auto;
  }

  // speciall measure
  @media (min-width: 636px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0 50px;
    margin: 20px auto auto -20px;
  }

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 60px; 
    margin: 60px auto;
  }
`;

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0 0 0;
  align-items: flex-start;
  padding: 0px;
  cursor: pointer;
  max-width: 300px;

  &:hover .link-line::after {
    width: 100%;
  }

  /* Mobile (default) */
  &:nth-child(odd) {
    margin-right: auto;
  }

  &:nth-child(even) {
    margin-left: auto;
  }

  /* Tablet  */
  @media (min-width: 768px) {
    margin: 30px 0 30px 60px;
    align-self: self-start
  }

  /* Desktop */
  @media (min-width: 1024px) {
    margin: 0;
    align-self: self-start;
  }
`;

interface ProfileImgProps {
  $margin?: string;
}

export const ProfileImg = styled.img<ProfileImgProps>`
  width: 140px;
  height: 140px;
  margin: ${({ $margin }) => $margin || '0'};


  /* Tablet */
  @media (min-width: 768px) {
    width: 150px;
    height: 150px;
    margin: 0;
  }

  /* Desktop */
  @media (min-width: 1024px) {
    width: 160px;
    height: 160px;
  }
`;

export const ProfileTitle = styled.p`
  color: var(--darktext);
  font-weight: 200;
  font-size: 14px;
  margin: 0;
  letter-spacing: 1px;
  line-height: 20px;
  text-decoration: none;
  width: 160px;
  text-align: left;
`;
