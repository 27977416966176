import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Footer = styled.footer`
  background-color: var(--mainlight);
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 50px 0;
`;

export const FootRow = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: flex-end;
`;

export const FootListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0 20px;
`;

export const FootLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 20px;
  align-items: center;
`;


export const NameLink = styled(Link) <{ to: string }>`
  color: var(--darktext);
  font-family: 'safiroregular';
  font-size: 16px; 
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
  margin: 5px 0;

  &:hover,
  &:focus {
      text-decoration: none;
  }

   /* Tablet */
   @media (min-width: 768px) {
    font-size: 18px; 
  }
`;

export const FootLinks = styled(Link) <{ to: string }>`
  position: relative;
  color: var(--darktext);
  font-family: 'safiroregular';
  font-size: 13px;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  margin: 5px 0;

  &:hover {
    color: var(--darktext);
  }

  &::before {
    content: "";
    position: absolute;
    display: block;
    width: 0;
    height: 4px;
    bottom: -2px;
    left: 0;
    background-color: var(--bg-pink);
    transform-origin: left;
    transition: width 0.5s ease;
  }

  &:hover::before {
    width: 100%;
  }

   /* Tablet */
   @media (min-width: 768px) {
    font-size: 16px; 
  }
`;

export const FootP = styled.p`
  color: var(--darktext);
  font-size: 10px; 
  font-weight: 200;
  margin: auto;
`;