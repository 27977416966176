import styled from 'styled-components';

// Outer container for the work with us page
export const WorkContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--bg-green);
  padding: 1rem;

  /* Bigger phone  */
  @media (min-width: 425px) {
    padding: 2rem;  
  }

   /* Tablet  */
 @media (min-width: 768px) {
    padding: 4rem;  
  }

  /* Desktop */
  @media (min-width: 1024px) {
    padding: 6rem;
  }
`;

// Container for contact information
export const FormContactContainer = styled.div`
    display: grid;
    grid-template-columns: 128px auto;
    align-items: start;
    gap: 11%;
    padding: 1rem 0;
    max-width: 900px;

    /* Bigger phone  */
    @media (min-width: 375px) {
      gap: 20%;  
    }

     /* Tablet  */
    @media (min-width: 768px) {
        grid-template-columns: 190px auto;
        gap: 35%;
      }

    /* Desktop */
      @media (min-width: 1024px) {
        grid-template-columns: 200px auto;
        gap: 38%;
      }
  `;

// Header used for contact information
export const FormContactHeader = styled.h6`
 color: var(--darktext);
 font-family: 'safiroregular';
 font-size: 16px;
 text-align: left;
 text-transform: uppercase;

 /* Tablet  */
 @media (min-width: 768px) {
  font-size: 22px;
  }

  /* Desktop */
  @media (min-width: 1024px) {
    font-size: 26px;
  }
`;

// Adresstext used in contact information
export const FormContactInfo = styled.p`
 color: var(--darktext);
 font-weight: 200;
 font-size: 16px;
 text-align: left;
 margin: 0;
 line-height: 20px;
 max-width: 100%;

  /* Tablet  */
  @media (min-width: 768px) {
  font-size: 22px;
  line-height: 26px;
  align-items: center;
  }

  /* Desktop */
  @media (min-width: 1024px) {
    font-size: 26px;
    line-height: 32px;
    max-width: 800px;
  }
`;

// Restyled LinkStatic used for contact Information
export const FormLinkForWork = styled.a`
  color: var(--darktext);
  margin: 0;
  padding: 0;
  font-weight: 200;
  font-size: 16px;
  text-decoration: underline;
  text-underline-offset: 6px;
  text-decoration-thickness: 0.3px;
  text-decoration-color: $var(--text);

  &:hover {
    text-decoration-thickness: 4px;
    text-decoration-color: var(--bg-pink);
  }
  
 /* Tablet  */
 @media (min-width: 768px) {
  font-size: 26px;
  }

  /* Desktop */
  @media (min-width: 1024px) {
    font-size: 26px;
  }
`;
