import React from "react";
import { useNavigate } from "react-router-dom";
import * as WhatStyle from "./../style/whatWeDo-style";
import {
  BaseContainer,
  PinkButton,
  TextContainer,
  IngressContainer,
  InfoContainer,
  IngressHeader,
  Bread,
} from "./../style/lib";
import { FadeInOnScroll } from "../gsap/scrollTrigg";
import system from "../../assets/systemdev.svg";
import solution from "../../assets/solution.svg";
import devops from "../../assets/devops.svg";
import webdev from "../../assets/webdev.svg";
import ai from "../../assets/ai.svg";
import { LineGradientSection } from "../gsap/transition";
// import { HorizontalScrollPart } from "../gsap/horizontalScroll";

export const WhatWeDoIntro: React.FC = () => {
  const navigate = useNavigate();

  const readMore = () => {
    navigate("/whatwedo");
  }



  return (
    <>

      <BaseContainer $backgroundColor='var(--bg-green)'
        $padding={['0.4rem 0 4rem', '1rem 0 5rem', '2rem 0 7rem']}>
        <TextContainer>
          <InfoContainer>
            <IngressContainer>
              <FadeInOnScroll>
                <IngressHeader>
                  Vad gör vi?
                </IngressHeader>
                <WhatStyle.TopHeader>
                  AndTech grundades med övertygelsen om att det är teknik som driver bolag framåt.
                </WhatStyle.TopHeader>
              </FadeInOnScroll>
            </IngressContainer>

            <FadeInOnScroll>
              <WhatStyle.WhatWeDoContainer>
                <WhatStyle.DevLogos src={system} alt="Systemutveckling" />
                <WhatStyle.TypeHeader>
                  Systemutveckling
                </WhatStyle.TypeHeader>
              </WhatStyle.WhatWeDoContainer>

              <Bread $margin={{ mobile: '8px 0', tablet: '10px 0', desktop: '14px 0' }}>
                Våra konsulter drivs av att skapa lösningar som driver bolag
                framåt och är en del av något större. Vi bygger lösningar som är, som vi brukar säga, framåtkompatibla.
              </Bread>
            </FadeInOnScroll>

            <FadeInOnScroll>
              <WhatStyle.WhatWeDoContainer>
                <WhatStyle.DevLogos src={solution} alt="Lösningsarkitektur" />
                <WhatStyle.TypeHeader>
                  Lösningsarkitektur & systemutveckling
                </WhatStyle.TypeHeader>
              </WhatStyle.WhatWeDoContainer>

              <Bread $margin={{ mobile: '8px 0', tablet: '10px 0', desktop: '14px 0' }}>
                Att se helheten i det vi gör och hur det påverkar framtida val är väldigt viktigt
                för oss. Lösningen är till för er.
              </Bread>
            </FadeInOnScroll>

            <FadeInOnScroll>
              <WhatStyle.WhatWeDoContainer>
                <WhatStyle.DevLogos src={devops} alt="DevOps" />
                <WhatStyle.TypeHeader>
                  DevOps
                </WhatStyle.TypeHeader>
              </WhatStyle.WhatWeDoContainer>

              <Bread $margin={{ mobile: '8px 0', tablet: '10px 0', desktop: '14px 0' }}>
                En bra IT-lösning är lika bra som sin DevOps-lösning.
                Vi är DevOps-experter och vi kan hjälpa till med att optimera hela livscykeln.
              </Bread>
            </FadeInOnScroll>

            <FadeInOnScroll>
              <WhatStyle.WhatWeDoContainer>
                <WhatStyle.DevLogos src={webdev} alt="Webbutveckling" />
                <WhatStyle.TypeHeader>
                  Webbutveckling
                </WhatStyle.TypeHeader>
              </WhatStyle.WhatWeDoContainer>

              <Bread $margin={{ mobile: '8px 0', tablet: '10px 0', desktop: '14px 0' }}>
                Kommunikation, Funktionalitet, Information och Känsla.
                Våra konsulter förstår vad ni behöver och skapar webbar som ger rätt värde.
              </Bread>
            </FadeInOnScroll>

            <FadeInOnScroll>
              <WhatStyle.WhatWeDoContainer>
                <WhatStyle.DevLogos src={ai} alt="Ai" />
                <WhatStyle.TypeHeader>
                  Ai
                </WhatStyle.TypeHeader>
              </WhatStyle.WhatWeDoContainer>

              <Bread $margin={{ mobile: '8px 0', tablet: '10px 0', desktop: '14px 0' }}>
                Vi ser potential i vad AI kan lösa för oss i framtiden.
                Vi hjälper er att förstå hur AI kan hjälpa er att effektivisera er verksamhet.
              </Bread>
            </FadeInOnScroll>

            <FadeInOnScroll>
              <PinkButton onClick={readMore} $backgroundColor="var(--bg-green)">Läs mer</PinkButton>
            </FadeInOnScroll>

          </InfoContainer>
        </TextContainer>
      </BaseContainer>
      <LineGradientSection
        containerBackgroundColor="var(--mainlight)"
        lineStartColor="var(--bg-green)"
        lineRightColor="var(--bg-green)"
        lineLeftColor="var(--bg-green)"
      />
    </>
  )
}