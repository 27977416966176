import React, { StrictMode } from 'react';
import { GlobalStyle } from './components/style/GlobalStyle';
import AppRoutes from './components/utils/routes';


function App() {
  return (
    <StrictMode>
      <div className="App">
        <GlobalStyle />
        <AppRoutes />

      </div>
    </StrictMode>
  );
}

export default App;
