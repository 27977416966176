import React, { useRef } from 'react';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import styled from 'styled-components';

interface CookieRollProps {
  cookieSrc: string;
  backgroundColor?: string;
}

const TestCont = styled.div<{ $backgroundColor?: string }>`
  display: flex;
  justify-content: flex-start;
  background-color: ${({ $backgroundColor }) => $backgroundColor || 'var(--bg-green)'};
  margin-bottom: 20px;
`;

const CookieContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 50px;
  height: 50px;
`;

const CookieImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const CookieRoll: React.FC<CookieRollProps> = ({ cookieSrc, backgroundColor }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useGSAP(() => {
    if (containerRef.current) {
      gsap.from(containerRef.current, {
        x: 600,
        scale: 0.2,
        opacity: 0,
        rotation: 960,
        duration: 1.5,
        ease: 'bounce',
      });
    }
  });

  return (
    <TestCont $backgroundColor={backgroundColor}>
      <CookieContainer ref={containerRef}>
        <CookieImage src={cookieSrc} alt="cookie" />
      </CookieContainer>
    </TestCont>
  );
};
