import lm from '../../assets/LM.svg';
import am from '../../assets/AM.svg';
import so from '../../assets/SO.svg';

interface Employee {
  name: string;
  title: string;
  image: string;
  information: Information;
}

export interface Information {
  name: string;
  title: string;
  image: string;
  text: string;
  email: string;
  linkedin: string;
}

export const Employees: Employee[] = [
  {
    name: "Lena",
    title: "CEO",
    image: lm,
    information: {
      name: "Lena Massaro",
      title: "CEO",
      image: lm,
      text: "Som VD för andtech arbetar Lena för att skapa ett konsultbolag som värnar om medarbetare och vårt samhällsklimat. Hennes hjärtefråga är att bygga en stark företagskultur där välmående och delaktighet blir centrala för framgång. Hon strävar efter att andtech ska ha medarbetare som har friheten att arbeta med sina passioner, utvecklas, vara kreativa och innovativa och må bra. Hennes bakgrund som miljökonsult och utbildning till stresspedagog kommer väl till pass.",
      email: "lena@andtech.se",
      linkedin: "https://www.linkedin.com/in/lenamassaro/"
    },
  },

  {
    name: "Andrea",
    title: "Arkitekt & DevOps proffs",
    image: am,
    information: {
      name: "Andrea Massaro",
      title: "Arkitekt & DevOps proffs",
      image: am,
      text: "Andrea är en inkluderande ledare som drivs av att förstå problem och finna helhetslösningar som fungerar för både verksamhet och IT. Med många års erfarenhet av både systemutveckling inom .net och olika DevOps verktyg leder han team att bygga lösningar som ger värde och inte efterlämnar teknisk skuld både i molntjänster och On-Prem. Han har mycket erfarenhet av branscher som resehandel, bank, retail och bemanning.",
      email: "andrea@andtech.se",
      linkedin: "https://www.linkedin.com/in/andreamassaro/"
    }
  },

  {
    name: "Simone",
    title: "Fullstack guru",
    image: so,
    information: {
      name: "Simone Odoardi",
      title: "Fullstack guru",
      image: so,
      text: "Simone är en teknisk visionär med en passion för att bygga skalbara och hållbara lösningar. Med expertkunskap i .NET, C#, Angular m.m. skapar han kod som är effektiv och återanvändbar. Han har gedigen erfarenhet från många olikabranscher som fintech, rese- och e-handel. Hans hjärta klappar extra för att integrera smarta AI-funktioner för att optimera verksamheter. ",
      email: "simone@andtech.se",
      linkedin: "https://www.linkedin.com/in/simoneodoardi/"
    }
  }
]