import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { MainLayout } from '../mainLayout';
import { SingleLayot } from '../singleLayout';
import { IntroPage } from '../pages/intro';
import { WhatWeDoIntro } from '../pages/whatWeDoIntro';
import { AboutUs } from '../pages/aboutUs';
import { Contact } from '../pages/contact';
import { WorkWithUs } from '../pages/workWithUs';
import { PrivacyPolicy } from '../utils/privacy';
import { NotFound } from '../pages/notFound';
import { WhatWeDo } from '../pages/whatWeDo';
import { CookieComponent } from '../utils/cookies';
import { ScrollToTop } from './scrollTo';

const AppRoutes: React.FC = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route
          path="/"
          element={
            <MainLayout>
              <IntroPage />
              <WhatWeDoIntro />
              <AboutUs />
              <Contact />
            </MainLayout>
          }
        />

        <Route
          path="/whatwedo"
          element={
            <MainLayout>
              <WhatWeDo />
            </MainLayout>
          }
        />

        <Route
          path="/workwithus"
          element={
            <MainLayout>
              <WorkWithUs />
            </MainLayout>
          }
        />
        <Route
          path="/privacy"
          element={
            <MainLayout>
              <PrivacyPolicy />
            </MainLayout>
          }
        />
        <Route
          path="*"
          element={
            <SingleLayot>
              <NotFound />
            </SingleLayot>
          }
        />
      </Routes>
      <CookieComponent />
    </Router>
  );
};

export default AppRoutes;
