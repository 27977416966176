import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import and from '../../assets/and.svg';
import tech from '../../assets/tech.svg';
import circle from '../../assets/at-circle.svg';

const IntroContainer = styled.div`
  display: flex;
  flex-direction: column; 
  align-items: center;
  position: relative;
  opacity: 0;
  margin-top: 100px;

    /* Tablet  */
    @media (min-width: 768px) {
      margin-top: 100px;
  }

    /* Desktop */
    @media (min-width: 1024px) {
      margin-top: 100px;
    }
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
`;

const AndLogo = styled.img`
  width: 210px;
  margin-bottom: 10px;
  margin-top: 10px;
`;

const TechLogo = styled.img`
  width: 210px;
`;

const CircleBackground = styled.img`
  width: 290px;
  height: 290px;
  opacity: 0;
`;

const IntroHeader = styled.h1`
  color: var(--bg-pink);
  font-family: 'safirobold';
  font-size: 33px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 45px;
  text-align: center;
  max-width: 90%;
  margin-top: -250px; 

  /* Tablet  */
  @media (min-width: 768px) {
    font-size: 42px;
    line-height: 110%;
  }

  /* Desktop */
  @media (min-width: 1024px) {
    font-size: 38px;
    line-height: 50px;
    max-width: 100%;
    margin-top: -200px;
  }
`;

export const IntroAnimation: React.FC = () => {
  const andLogoRef = useRef<HTMLImageElement>(null);
  const techLogoRef = useRef<HTMLImageElement>(null);
  const circleBackgroundRef = useRef<HTMLImageElement>(null);
  const introContainerRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    const tl = gsap.timeline({ defaults: { ease: 'back' } });

    // Timeline for intro animation
    tl.to(introContainerRef.current, { opacity: 1, duration: 0.5 })
      .fromTo(andLogoRef.current,
        { x: 300, opacity: 0 },
        { x: 0, opacity: 1, duration: 0.8 }
      )

      .fromTo(techLogoRef.current,
        { x: -300, opacity: 0 },
        { x: 0, opacity: 1, duration: 0.9 },
        "<"
      )

      .fromTo(circleBackgroundRef.current,
        { y: -300, opacity: 1, scale: 0.8, clipPath: 'inset(100% 0 0 0)' },
        { opacity: 1, scale: 1, clipPath: 'inset(0% 0 0 0)', duration: 1.1, ease: 'back' },
        "-=0.3"
      );

    // Separate timeline for the header animation
    gsap.fromTo(headerRef.current,
      { y: 180, opacity: 0, scale: 0.8 },
      { y: 0, opacity: 1, scale: 1, duration: 0.8, ease: 'power2.out', delay: 0.5 }
    );

  }, []);

  return (
    <>
      <IntroContainer ref={introContainerRef}>
        <LogoContainer>
          <AndLogo ref={andLogoRef} src={and} alt="And logo" />
          <TechLogo ref={techLogoRef} src={tech} alt="Tech logo" />
        </LogoContainer>
        <CircleBackground
          ref={circleBackgroundRef}
          src={circle}
          alt="Background Circle"
        />
      </IntroContainer>
      <IntroHeader ref={headerRef}>
        Nutidens konsultbolag <br />
        för framtidens lösningar
      </IntroHeader>
    </>
  );
};