// src/Modal.tsx
import React from 'react';
import { useLockBodyScroll } from 'react-use';
import { LinkStatic } from '../style/lib';
import {
  ModalThing,
  ModalOverlay,
  ModalClose,
  NameModal,
  TitleModal,
  InfoBread,
  CrossImg,
  LogoImg
} from '../style/modal-style';
import { Information } from './employees';
import cross from '../../assets/cross.svg'
import atlogo from '../../assets/at-logo.svg';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  information: Information;
}

export const Modal: React.FC<ModalProps> = ({ isOpen, onClose, information }) => {
  useLockBodyScroll(isOpen);

  if (!isOpen) return null;


  return (
    <ModalOverlay onClick={onClose} $isOpen={isOpen} >
      <ModalThing>
        <ModalClose className="modal-close" onClick={onClose}>
          <CrossImg src={cross} alt="close" />
        </ModalClose>
        <NameModal>{information.name}</NameModal>
        <TitleModal>{information.title}</TitleModal>

        <InfoBread>{information.text}</InfoBread>

        <LinkStatic href={`mailto:${information.email}`}>{information.email}</LinkStatic>
        <LinkStatic href={information.linkedin}>Linkedin</LinkStatic>
        <LogoImg src={atlogo} alt="AndTech logo" />
      </ModalThing>
    </ModalOverlay>
  );
};


