import React, { useRef } from 'react';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn, faSpotify } from '@fortawesome/free-brands-svg-icons';
import styled from 'styled-components';

const TestCont = styled.div`
  display: flex;
  justify-content: flex-start;
  background-color: var(--bg-pink);
`;

const Icons = styled.a`
  color: var(--bg-pink);
  font-size: 22px;
  text-decoration: none;
  background-color: var(--maindark);
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  @media (min-width: 768px) {
    font-size: 28px;
    width: 50px;
    height: 50px;
  }

  @media (min-width: 1024px) {
    font-size: 30px;
    width: 60px;
    height: 60px;
  }
`;

const BgCircle = styled.div`
  position: absolute;
  background: var(--bg-pink);
  border-radius: 50%;
  width: 100%;
  height: 100%;
  will-change: transform;
  z-index: -1;
`;

const FaIcons = styled.div`
  display: flex;
  margin-top: 80px;
  gap: 34px;

  @media (min-width: 768px) {
    gap: 32px;
    margin-top: 90px;
  }

  @media (min-width: 1024px) {
    gap: 34px;
  }
`;

export const FollowUs: React.FC = () => {
  const followContainer = useRef<HTMLDivElement>(null);

  useGSAP(() => {
    if (followContainer.current) {
      const icons = Array.from(followContainer.current.children) as HTMLElement[];

      icons.forEach((icon) => {
        const bgCircle = icon.querySelector('.bg-circle') as HTMLElement;

        // Needed to keep the initial state of the elements
        gsap.set(bgCircle, { scale: 0, opacity: 0 });

        const enterAnimation = gsap.timeline({ paused: true })
          .to(bgCircle, { scale: 1, opacity: 0.3, duration: 0.5, ease: 'circ.out' })
          .to(icon, { scale: 1.2, duration: 0.5, ease: 'back' }, 0);

        const leaveAnimation = gsap.timeline({ paused: true })
          .to(bgCircle, { scale: 0, opacity: 0, duration: 0.3, ease: 'circ.in' })
          .to(icon, { scale: 1, duration: 0.3, ease: 'back' }, 0);

        icon.addEventListener('mouseenter', () => {
          leaveAnimation.pause();
          enterAnimation.restart();
        });

        icon.addEventListener('mouseleave', () => {
          enterAnimation.pause();
          leaveAnimation.restart();
        });
      });

      gsap.from(icons, {
        opacity: 0,
        duration: 0.7,
        scale: 1.2,
        stagger: 0.2,
      });
    }
  }, { scope: followContainer });

  return (
    <TestCont>
      <FaIcons ref={followContainer}>
        <Icons
          href="https://www.linkedin.com/company/andtech-ab/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Visit Andtech on LinkedIn"
        >
          <BgCircle className="bg-circle" />
          <FontAwesomeIcon icon={faLinkedinIn} />
        </Icons>

        <Icons
          href="https://open.spotify.com/show/1kkUEywn15RuZ9qquCfhgb?si=0243ad00d264495b"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Visit Andtech on Spotify"
        >
          <BgCircle className="bg-circle" />
          <FontAwesomeIcon icon={faSpotify} />
        </Icons>

      </FaIcons>
    </TestCont>
  );
};
