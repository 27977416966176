import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import {
  FromInputContainer,
  RowContainer,
  Form,
  InputContacts,
  Label,
  Textarea,
  ErrorMessage,
  CheckboxContainer,
  CheckboxInput,
  CheckboxLabel
} from '../style/contactForm-style';
import { BaseContainer, PinkButton } from '../style/lib';

const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID!;
const templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID!;
const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY!;

export const ContactForm: React.FC = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  });

  const [acceptConditions, setAcceptConditions] = useState(false);
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateForm = () => {
    const errors: { [key: string]: string } = {};
    if (!formData.firstName) errors.firstName = '* Förnamn är obligatoriskt';
    if (!formData.lastName) errors.lastName = '* Efternamn är obligatoriskt';
    if (!formData.email) {
      errors.email = '* E-post är obligatoriskt';
    } else if (!validateEmail(formData.email)) {
      errors.email = 'E-post måste vara i ett giltigt format (t.ex. example@example.com)';
    }
    if (!formData.message) errors.message = '* Meddelande är obligatoriskt';
    if (!acceptConditions) errors.acceptConditions = '* Du måste acceptera villkoren för att fortsätta.';
    return errors;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAcceptConditions(e.target.checked);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    emailjs.send(serviceID, templateID, formData, publicKey).then(
      (response) => {
        alert('Ditt mail är skickat, tack!');
        setFormErrors({});
        // Reset the form
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          message: ''
        });
        setAcceptConditions(false);
      },
      (error) => {
        console.error('Misslyckades att skicka:', error);
        alert('Misslyckades att skicka.');
      }
    );
  };

  return (
    <BaseContainer
      $backgroundColor="var(--bg-green)"
      $alignItems="flex-start"
      $padding={['1rem 1rem', '3rem 0', '4rem 3rem']}>
      <Form onSubmit={handleSubmit} noValidate >
        <RowContainer>
          <FromInputContainer>
            <Label htmlFor="firstName">Förnamn *</Label>
            <InputContacts
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              aria-label="Förnamn"
              autoComplete="given-name"
            />
            {formErrors.firstName && <ErrorMessage>{formErrors.firstName}</ErrorMessage>}
          </FromInputContainer>

          <FromInputContainer>
            <Label htmlFor="lastName">Efternamn *</Label>
            <InputContacts
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              aria-label="Efternamn"
              autoComplete="family-name"
            />
            {formErrors.lastName && <ErrorMessage>{formErrors.lastName}</ErrorMessage>}
          </FromInputContainer>
        </RowContainer>

        <RowContainer>
          <FromInputContainer>
            <Label htmlFor="email">E-post *</Label>
            <InputContacts
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              aria-label="E-post"
              autoComplete="email"
            />
            {formErrors.email && <ErrorMessage>{formErrors.email}</ErrorMessage>}
          </FromInputContainer>

          <FromInputContainer>
            <Label htmlFor="phone">Mobil</Label>
            <InputContacts
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              aria-label="Mobil"
              autoComplete="tel"
            />
          </FromInputContainer>
        </RowContainer>

        <FromInputContainer>
          <Label htmlFor="message">Skriv till oss *</Label>
          <Textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            aria-label="Message"
          ></Textarea>
          {formErrors.message && <ErrorMessage>{formErrors.message}</ErrorMessage>}
        </FromInputContainer>

        <CheckboxContainer>
          <CheckboxInput
            type="checkbox"
            id="acceptConditions"
            name="acceptConditions"
            checked={acceptConditions}
            onChange={handleCheckboxChange}
            aria-label="Jag accepterar villkoren"
          />
          <CheckboxLabel>
            Jag accepterar villkoren *
          </CheckboxLabel>
        </CheckboxContainer>

        {formErrors.acceptConditions && <ErrorMessage>{formErrors.acceptConditions}</ErrorMessage>}

        <PinkButton type="submit" $backgroundColor="transparent">
          Skicka
        </PinkButton>
      </Form>
    </BaseContainer >
  );
};
