// src/components/LottieAnimation.tsx
import React, { useEffect, useState } from 'react';
import Lottie from 'lottie-react';
import styled from 'styled-components';

// Styled container for the animation
const AnimationContainer = styled.div<{ $fliphorizontal?: boolean }>`
  width: 100px;
  height: 100px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: ${({ $fliphorizontal }) => $fliphorizontal ? 'scaleX(-1)' : 'none'};

   /* Tablet*/
  @media (min-width: 768px) {
    width: 170px;
    height: 170px;
  }

    /* Desktop */
    @media (min-width: 1024px) {
      width: 200px;
      height: 200px;
  }
`;

// Placeholder
const Placeholder = styled.div`
  width: 100%;
  height: 100%;
`;

export const LottieContainer = styled.div` 
display: flex;
justify-content: flex-end;
margin: -90px -30px -20px 0;
max-width: 700px;
visibility: hidden;

   /* bigger mobile  */
   @media (min-width: 484px) {
    margin: -90px 5px -20px 0;
    visibility: visible;
  }

   /* Tablet  */
   @media (min-width: 768px) {
    margin: -70px -40px -40px 0;
    visibility: visible;
  }

  /* Desktop */
  @media (min-width: 1024px) {
    margin: -80px 0 -70px 70px;
    max-width: 1000px;
  }

  /* Big Desktop */
  @media (min-width: 1340px) {
    margin: -100px 0 -68px 0;
    max-width: 1000px;
  }
`;

export const LottieAnimation: React.FC = () => {
  const [animationData, setAnimationData] = useState<any>(null);

  useEffect(() => {
    fetch('https://lottie.host/6663d6e0-d59f-4569-b8bd-ca760affcdd2/QwCyy7741v.json')
      .then(response => response.json())
      .then(data => setAnimationData(data))
      .catch(error => console.error('Error loading Lottie animation:', error));
  }, []);

  return (
    <LottieContainer>
      <AnimationContainer $fliphorizontal={true}>
        {animationData ? (
          <Lottie
            animationData={animationData}
            loop={true}
            style={{ width: '100%', height: '100%' }}
          />
        ) : (
          <Placeholder />
        )}
      </AnimationContainer>
    </LottieContainer>
  );
};
