import React, { useState, useEffect } from 'react';
import { useLockBodyScroll } from 'react-use';
import { slide as Menu } from 'react-burger-menu';
import { LinkLine } from '../style/lib';
import bar from '../../assets/bar.svg';
import cross from '../../assets/cross.svg';
import { useNavigate } from 'react-router-dom';

const HamburgerMenu: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuWidth, setMenuWidth] = useState('600px');
  const navigate = useNavigate();

  useLockBodyScroll(menuOpen);

  const handleStateChange = (state: { isOpen: boolean }) => {
    setMenuOpen(state.isOpen);
  };

  const closeMenu = (path: string) => {
    setMenuOpen(false);
    navigate(path);
  };

  // Function to determine and set the menu width based on screen size
  const updateMenuWidth = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth < 768) {
      //
      setMenuWidth('100%');
    } else if (screenWidth >= 768 && screenWidth < 1024) {
      // tablet
      setMenuWidth('650px');
    } else {
      // desktop
      setMenuWidth('500px');
    }
  };

  // Update on window resize and on component mount
  useEffect(() => {
    updateMenuWidth();
    window.addEventListener('resize', updateMenuWidth);
    return () => {
      window.removeEventListener('resize', updateMenuWidth);
    };
  }, []);

  // Styling
  const customStyles = {
    bmBurgerButton: {
      position: 'absolute',
      width: '26px',
      height: '20px',
      right: '36px',
      top: '36px',
    },
    bmCrossButton: {
      margin: '27px 32px',
    },
    bmMenuWrap: {
      position: 'fixed',
      height: '100%',
      width: menuWidth,
    },
    bmMenu: {
      background: 'var(--maindark)',
      padding: '8em 2.5em 0',
      width: menuWidth,
    },
    bmItemList: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      textTransform: 'uppercase',
    },
    bmItem: {
      margin: '15px 0',
    }
  };

  return (
    <Menu
      customBurgerIcon={<img src={bar} alt="open" />}
      customCrossIcon={<img src={cross} alt="close" />}
      right
      disableAutoFocus
      isOpen={menuOpen}
      onStateChange={handleStateChange}
      styles={customStyles}
    >
      <LinkLine
        onClick={() => closeMenu('/')}
        id="home"
        to="/"
        $textColor="var(--mainlight)"
        $lineColor="var(--bg-pink)"
      >
        Start
      </LinkLine>

      <LinkLine
        onClick={() => closeMenu('/#workwithus')}
        id="workwithus"
        to="/workwithus"
        $textColor="var(--mainlight)"
        $lineColor="var(--bg-pink)"
      >
        Jobba med oss
      </LinkLine>


      <LinkLine
        onClick={() => closeMenu('/#whatwedo')}
        id="whatwedo"
        to="/#whatwedo"
        $textColor="var(--mainlight)"
        $lineColor="var(--bg-pink)"
      > Tjänster
      </LinkLine>

      <LinkLine
        onClick={() => closeMenu('/#aboutus')}
        id="contact"
        to="/#aboutus"
        $textColor="var(--mainlight)"
        $lineColor="var(--bg-pink)"
      >
        Om oss
      </LinkLine>

      <LinkLine
        onClick={() => closeMenu('/#contactus')}
        id="contact"
        to="/#contactus"
        $textColor="var(--mainlight)"
        $lineColor="var(--bg-pink)"
      >
        Kontakt
      </LinkLine>

    </Menu>
  );
};

export default HamburgerMenu;
