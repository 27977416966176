import styled from 'styled-components';

export const WhatWeDoContainer = styled.div`
 display: flex;
 flex-direction: row;  
 align-items: center;
 gap: 10px;
 margin-top: 18px;
`;

// For ingress text on whatWeDoIntro.tsx
export const TopHeader = styled.h6`
 color: var(--darktext);
 font-family: 'safiromedium';
 font-weight: 500;
 font-size: 16px;
 text-align: left;
 text-transform: uppercase;
 margin: 18px 0;

 /* Tablet  */
 @media (min-width: 768px) {
  font-size: 26px;
  }

  /* Desktop */
  @media (min-width: 1024px) {
    font-size: 30px;
  }
`;


export const TypeHeader = styled.h6`
 color: var(--darktext);
 font-family: 'safiromedium';
 font-weight: 500;
 font-size: 17px;
 text-align: left;

 margin: 8px 0;

 /* Tablet  */
 @media (min-width: 768px) {
  font-size: 26px;
  }

  /* Desktop */
  @media (min-width: 1024px) {
    font-size: 30px;
  }
`;

// Illustrated Logos infront of TypeHeader
export const DevLogos = styled.img`
  width: 30px;
  margin: 0;

   /* Tablet  */
 @media (min-width: 768px) {
  width: 35px;
  }

  /* Desktop */
  @media (min-width: 1024px) {
    width: 40px;
  }
`;

export const WhatCoulmnContainer = styled.div`
 display: flex; 
 flex-direction: column;
 align-items: left;
 gap: 10px;
`;