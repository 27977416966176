import styled from 'styled-components';

// Container for contact information
export const ContactContainer = styled.div`
    display: grid;
    grid-template-columns: 130px auto;
    align-items: start;
    gap: 10%;
    padding: 1rem 0;
    width: 100%;

    /* Bigger phone  */
    @media (min-width: 375px) {
      gap: 20%;  
    }

     /* Tablet  */
    @media (min-width: 768px) {
        grid-template-columns: 190px auto;
        gap: 35%;
      }

    /* Desktop */
      @media (min-width: 1024px) {
        grid-template-columns: 200px auto;
      }
  `;


// Header used for contact information
export const ContactHeader = styled.h6`
 color: var(--darktext);
 font-family: 'safiroregular';
 font-size: 16px;
 text-align: left;
 text-transform: uppercase;

 /* Tablet  */
 @media (min-width: 768px) {
  font-size: 22px;
  }

  /* Desktop */
  @media (min-width: 1024px) {
    font-size: 26px;
  }
`;

// Adresstext used in contact information
export const ContactInfo = styled.p`
 color: var(--darktext);
 font-family: 'Inter', sans-serif;
 font-weight: 200;
 font-size: 16px;
 text-align: left;
 margin: 0;
 line-height: 20px;
 width: 100%;

  /* Tablet  */
  @media (min-width: 768px) {
  font-size: 22px;
  line-height: 26px;
  align-items: center;
  }

  /* Desktop */
  @media (min-width: 1024px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

// Restyled LinkStatic used for contact Information
export const LinkForWork = styled.a`
  color: var(--darktext);
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  font-weight: 200;
  font-size: 16px;
  text-decoration: underline;
  text-underline-offset: 5px;
  text-decoration-thickness: 0.4px;
  text-decoration-color: $var(--text);

  &:hover {
    text-decoration-thickness: 4px;
    text-decoration-color: var(--blue);
  }
  
 /* Tablet  */
 @media (min-width: 768px) {
  font-size: 22px;
  }

  /* Desktop */
  @media (min-width: 1024px) {
    font-size: 24px;
  }
`;

